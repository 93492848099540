<template>
    <div class="w-100 h-100">
          <b-card class="mb-3 w-100 h-100">
              <b-overlay :show="loading_list" :opacity="0">
              <b-row class="app-card-header">
                  
                  <b-col start>
                      <div class="app-card-tittle app-local-title-height">
                          <div> {{trans('d-compliancy',203)}} ({{ items.total }}) </div>
                      </div>
                  </b-col>
                  
  
                  <b-col class="app-input-applicant-filter-width pl-0" >
                      <b-form-input type="text" v-model="filters.search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': filters.search.length > 0}" :placeholder="trans('search',3)" size="sm"/>                       
                  </b-col>
  
                  <b-col cols="auto" class="pl-0"  end>
                      <b-button-group  size="sm">
                          <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 1, 'app-list-button-selected':  filters.type === 1 }" @click="filters.type = 1">{{trans('fp-status-salaried-service',172)}}</b-btn>
                          <b-btn class="app-input-min-height app-list-button ml-n1" v-bind:class="{ 'btn-secondary-grey': filters.type !== 2, 'app-list-button-selected':  filters.type === 2 }" @click="filters.type = 2">{{trans('fp-self-employed',172)}}</b-btn>
                          <b-btn class="app-input-min-height app-list-button ml-n1" v-bind:class="{ 'btn-secondary-grey': filters.type !== 3, 'app-list-button-selected':  filters.type === 3 }" @click="filters.type = 3">{{trans('fl-student',176)}}</b-btn>
                      </b-button-group>                             
                  </b-col>
  
                  <b-col class="app-input-applicant-filter-width pl-0" >
                      <app-select v-model="filters.office" :type="user_role > 2 ? 'getUserLocations' : 'getLocations'" :refreshIndex.sync="refreshUserLocations" @loaded="getFiltersOffices"/>
                  </b-col>
  
                  <b-col class="app-input-applicant-filter-width pl-0" >
                      <app-select v-model="filters.list_type" customBody :options="list_options" :clearable="false" :selectable="(item) => item.value > 0">
                          <template v-slot:option="option">
                              <hr v-if="option.data.value < 0" class="app-search-modal-separator">    
                              <span  v-else>{{ option.data.name }}</span>
                          </template>
                      </app-select>
                  </b-col>
   
                  <b-col cols="auto" end class="d-flex align-items-center pl-0">
                      <app-refresh :refresh.sync="items.refresh" :loading.sync="loading_list"/>
                  </b-col>
              </b-row>
  
  
                  <app-view-table :title="trans('d-compliancy',203)" :isTitle="false" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :filterOptions="list_options" :isFilterOptions="false" :items="items" v-on:onRowSelected="getProfile" :fields="fields" :isSearch="false" :isSettings="false" :isDropdownMenu="false" class="table-selectable" :customColumns="customColumns" :customColumnsHeaders="custom_columns_headers">
                      
                      <!-- ID -->
                      <template v-slot:compliance_id="cell">
                              <div :id="'popover_id_' + cell.data.item.id_person_data_main" class="app-local-center">
                                  
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_id == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_id == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_id == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                                  
                              </div>
  
                              <b-popover :target="'popover_id_' + cell.data.item.id_person_data_main" triggers="hover" no-fade  placement="right">
                                  <template #title>
                                     <p class="mb-0 app-popover-small-text font-weight-600">{{trans('settings-data',207)}}</p>
                                  </template>
  
                                  <b-row class="align-items-center">
                                      <b-col start>
                                          <p class="app-popover-small-text mb-0">{{trans('dashboard-expiration-date',201)}}</p>
                                      </b-col>
                                      <b-col cols="auto" end>
                                          <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{cell.data.item.compliance_id_description != null ? cell.data.item.compliance_id_description : ' - '}}</p>
                                      </b-col>
                                  </b-row>
                              </b-popover>
                      </template>
  
                      <!-- DIPLOMA -->
                      <template v-slot:compliance_diploma="cell">
                              <div class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_diploma == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_diploma == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_diploma == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
                      </template>
  
                      <!-- BIG-->
                      <template v-slot:compliance_big="cell">
                              <div :id="'popover_big_' + cell.data.item.id_person_data_main" class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-b-tooltip.hover.top :title="cell.data.item.compliance_big_description" v-if="cell.data.item.compliance_big == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-b-tooltip.hover.top :title="cell.data.item.compliance_big_description" v-else-if="cell.data.item.compliance_big == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-b-tooltip.hover.top :title="cell.data.item.compliance_big_description" v-else-if="cell.data.item.compliance_big == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-b-tooltip.hover.top :title="cell.data.item.compliance_big_description" v-else></b-icon>
                              </div>
  
                              <b-popover :target="'popover_big_' + cell.data.item.id_person_data_main" triggers="hover" no-fade  placement="right">
                                  <template #title>
                                     <p class="mb-0 app-popover-small-text font-weight-600">{{trans('settings-data',207)}}</p>
                                  </template>
  
                                  <b-row class="align-items-center">
                                      <b-col start>
                                          <p class="app-popover-small-text mb-0">{{trans('dashboard-last-check-date',201)}}</p>
                                      </b-col>
                                      <b-col cols="auto" end>
                                          <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{cell.data.item.compliance_big_description != null ? cell.data.item.compliance_big_description : ' - '}}</p>
                                      </b-col>
                                  </b-row>
                              </b-popover>
                      </template>
  
                      <!-- VOG -->
                      <template v-slot:compliance_vog="cell">
                              <div :id="'popover_vog_' + cell.data.item.id_person_data_main" class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_vog == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_vog == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_vog == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
  
                              <b-popover :target="'popover_vog_' + cell.data.item.id_person_data_main" triggers="hover" no-fade  placement="right">
                                  <template #title>
                                     <p class="mb-0 app-popover-small-text font-weight-600">{{trans('settings-data',207)}}</p>
                                  </template>
  
                                  <b-row class="align-items-center">
                                      <b-col start>
                                          <p class="app-popover-small-text mb-0">{{trans('dashboard-expiration-date',201)}}</p>
                                      </b-col>
                                      <b-col cols="auto" end>
                                          <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{cell.data.item.compliance_vog_description != null ? cell.data.item.compliance_vog_description : ' - '}}</p>
                                      </b-col>
                                  </b-row>
                              </b-popover>
                      </template>
  
                      <!-- CV -->
                      <template v-slot:compliance_cv="cell">
                              <div class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_cv == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_cv == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_cv == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
                      </template>
  
                      <!-- KVK -->
                      <template v-slot:compliance_kvk="cell">
                              <div :id="'popover_kvk_' + cell.data.item.id_person_data_main" class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-b-tooltip.hover.top :title="cell.data.item.compliance_kvk_description" v-if="cell.data.item.compliance_kvk == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-b-tooltip.hover.top :title="cell.data.item.compliance_kvk_description" v-else-if="cell.data.item.compliance_kvk == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-b-tooltip.hover.top :title="cell.data.item.compliance_kvk_description" v-else-if="cell.data.item.compliance_kvk == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-b-tooltip.hover.top :title="cell.data.item.compliance_kvk_description" v-else></b-icon>
                              </div>
  
                              <b-popover :target="'popover_kvk_' + cell.data.item.id_person_data_main" triggers="hover" no-fade  placement="right">
                                  <template #title>
                                     <p class="mb-0 app-popover-small-text font-weight-600">{{trans('settings-data',207)}}</p>
                                  </template>
  
                                  <b-row class="align-items-center">
                                      <b-col start>
                                          <p class="app-popover-small-text mb-0">{{trans('dashboard-last-check-date',201)}}</p>
                                      </b-col>
                                      <b-col cols="auto" end>
                                          <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{cell.data.item.compliance_kvk_description != null ? cell.data.item.compliance_kvk_description : ' - '}}</p>
                                      </b-col>
                                  </b-row>
                              </b-popover>
                      </template>
  
                      <!-- GESH -->
                      <template v-slot:compliance_gesh="cell">
                              <div class="app-local-center" :id="'popover_gesh_' + cell.data.item.id_person_data_main">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-b-tooltip.hover.top :title="cell.data.item.compliance_gesh_description" v-if="cell.data.item.compliance_gesh == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-b-tooltip.hover.top :title="cell.data.item.compliance_gesh_description" v-else-if="cell.data.item.compliance_gesh == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-b-tooltip.hover.top :title="cell.data.item.compliance_gesh_description" v-else-if="cell.data.item.compliance_gesh == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-b-tooltip.hover.top :title="cell.data.item.compliance_gesh_description" v-else></b-icon>
                              </div>
  
                              <b-popover :target="'popover_gesh_' + cell.data.item.id_person_data_main" triggers="hover" no-fade  placement="right">
                                  <template #title>
                                     <p class="mb-0 app-popover-small-text font-weight-600">{{trans('settings-data',207)}}</p>
                                  </template>
  
                                  <b-row class="align-items-center">
                                      <b-col start>
                                          <p class="app-popover-small-text mb-0">{{trans('dashboard-expiration-date',201)}}</p>
                                      </b-col>
                                      <b-col cols="auto" end>
                                          <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{cell.data.item.compliance_gesh_description != null ? cell.data.item.compliance_gesh_description : ' - '}}</p>
                                      </b-col>
                                  </b-row>
                              </b-popover>
                      </template>
  
                      <!-- BEROEPSAN -->
                      <template v-slot:compliance_beroepsan="cell">
                              <div class="app-local-center" :id="'popover_bereospan_' + cell.data.item.id_person_data_main" >
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-b-tooltip.hover.top :title="cell.data.item.compliance_beroepsan_description" v-if="cell.data.item.compliance_beroepsan == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-b-tooltip.hover.top :title="cell.data.item.compliance_beroepsan_description" v-else-if="cell.data.item.compliance_beroepsan == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-b-tooltip.hover.top :title="cell.data.item.compliance_beroepsan_description" v-else-if="cell.data.item.compliance_beroepsan == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-b-tooltip.hover.top :title="cell.data.item.compliance_beroepsan_description" v-else></b-icon>
                              </div>
  
                              <b-popover :target="'popover_bereospan_' + cell.data.item.id_person_data_main" triggers="hover" no-fade  placement="right">
                                  <template #title>
                                     <p class="mb-0 app-popover-small-text font-weight-600">{{trans('settings-data',207)}}</p>
                                  </template>
  
                                  <b-row class="align-items-center">
                                      <b-col start>
                                          <p class="app-popover-small-text mb-0">{{trans('dashboard-expiration-date',201)}}</p>
                                      </b-col>
                                      <b-col cols="auto" end>
                                          <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{cell.data.item.compliance_beroepsan_description != null ? cell.data.item.compliance_beroepsan_description : ' - '}}</p>
                                      </b-col>
                                  </b-row>
                              </b-popover>
                      </template>
  
                      <!-- LRZA -->
                      <template v-slot:compliance_lrza="cell">
                              <div class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_lrza == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_lrza == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_lrza == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
                      </template>
  
                      <!-- REFERENCE -->
                      <template v-slot:compliance_reference="cell">
                              <div class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_reference == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_reference == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_reference == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
                      </template>
  
                      <!-- COMPLIANCE PAYROLL TAX -->
                      <template v-slot:compliance_payroll_tax="cell">
                              <div :id="'popover_payroll_tax_' + cell.data.item.id_person_data_main" class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_payroll_tax == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_payroll_tax == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_payroll_tax == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
  
                              <b-popover :target="'popover_payroll_tax_' + cell.data.item.id_person_data_main" triggers="hover" no-fade  placement="right">
                                  <template #title>
                                     <p class="mb-0 app-popover-small-text font-weight-600">{{trans('settings-data',207)}}</p>
                                  </template>
  
                                  <b-row class="align-items-center">
                                      <b-col>
                                          <p class="app-popover-small-text mb-0">{{trans('status',3)}}</p>
                                      </b-col>
                                      <b-col cols="auto" end>
                                          <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{cell.data.item.compliance_payroll_tax_description != null && cell.data.item.compliance_payroll_tax_description.length > 0 ? cell.data.item.compliance_payroll_tax_description : ' - '}}</p>
                                      </b-col>
                                  </b-row>
                              </b-popover>
                      </template>
  
                      <!-- COMPLIANCE BTW NUMBER -->
                      <template v-slot:compliance_btw_number="cell">
                              <div class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_btw_number == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_btw_number == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_btw_number == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
                      </template>
  
                      <!-- COMPLIANCE PROOF REGISTRATION -->
                      <template v-slot:compliance_proof_registration="cell">
                              <div class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_proof_registration == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_proof_registration == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_proof_registration == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
                      </template>

                      <!-- COMPLIANCE TRAINING CONTRACT-->
                      <template v-slot:compliance_training_contract="cell">
                              <div class="app-local-center">
                                  <b-icon icon="x-square-fill" font-scale="1.25" class="app-local-danger" v-if="cell.data.item.compliance_training_contract == 0"></b-icon>
                                  <b-icon icon="check-square-fill" font-scale="1.25" class="app-local-success" v-else-if="cell.data.item.compliance_training_contract == 3"></b-icon>
                                  <b-icon icon="exclamation-square-fill" font-scale="1.25" class="app-local-warning" v-else-if="cell.data.item.compliance_training_contract == 2"></b-icon>
                                  <b-icon icon="square-fill" font-scale="1.25" class="app-local-not-applicable" v-else></b-icon>
                              </div>
                      </template>
  
  
                      <template v-slot:head_compliance_diploma="">
                          <span v-overflow-tooltip>{{trans('d-diploma',203)}}</span>
                      </template>
  
                      <template v-slot:head_compliance_gesh="">
                          <span v-overflow-tooltip>{{trans('pw-dispute-committee',196)}}</span>
                      </template>
  
                      <template v-slot:head_compliance_beroepsan="">
                          <span v-overflow-tooltip>{{trans('pw-professional-liability',196)}}</span>
                      </template>
  
                      <template v-slot:head_compliance_reference="">
                          <span v-overflow-tooltip>{{trans('fp-reference',172)}}</span>
                      </template>
  
                      <template v-slot:head_compliance_payroll_tax="">
                          <span v-overflow-tooltip>{{trans('dashboard-payroll-tax-statement',201)}}</span>
                      </template>
  
                      <template v-slot:head_compliance_proof_registration="">
                          <span v-overflow-tooltip>{{trans('fw-proof-of-registration',196)}}</span>
                      </template>
  
                  </app-view-table>
  
                  <template #overlay>
                      <div></div>
                  </template>
              </b-overlay>
          </b-card>
      </div>
  </template>
  <script>
   
  import axios from "@axios";
  import UserSettings from '@core/scripts/UserSettings';
  
    export default {
  
      created(){
           this.user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
      },
  
      mounted() {
          this.onCreateThis();
      },
  
      computed: {
  
      },
  
      data() {
          return {
              loading_list: false,
              refreshUserLocations:0,
              load: 0,
              customColumns: ["compliance_id", "compliance_diploma", "compliance_big", "compliance_vog", "compliance_reference", "compliance_cv", "compliance_kvk", "compliance_lrza", "compliance_gesh", "compliance_beroepsan", "compliance_payroll_tax", "compliance_btw_number", "compliance_proof_registration", "compliance_training_contract"],
              custom_columns_headers: ["compliance_diploma", "compliance_gesh", "compliance_beroepsan", "compliance_reference", "compliance_payroll_tax", "compliance_btw_number", "compliance_proof_registration"],
              filters: {
                  sorting: {
                          field_name: null,
                          sort_direction: 0,
                          changed: 0
                  },
                  filtering: {},
                  page: 1,
                  per_page: 10,
                  sortBy: "person_name",
                  sortDesc: true,
                  search: "",
                  list_type: 3,
                  changed: 0,
                  type: 1,
                  office:null,
                  register_status: 2
              },
              fields: [
                  { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "100px", width: "100%"}, visible: true, filtering: false },
                  { key: "compliance_id", label: this.trans('settings-id',207), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "25px"  }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_diploma", label: this.trans('d-diploma',203), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "14px"  }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_big", label: this.trans('big',184), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "25px"  }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_vog", label: this.trans('l-vog',182), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "25px"  }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_cv", label: this.trans('cv',182), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "25px"  }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_kvk", label: this.trans('l-kvk',182), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "25px"  }, visible: false, filtering: false, sortable: false},
                  { key: "compliance_gesh", label: this.trans('pw-dispute-committee',196), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "10px", "padding-right": "5px"  }, visible: false, filtering: false, sortable: false},
                  { key: "compliance_beroepsan", label: this.trans('pw-professional-liability',196), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "15px", "padding-right": "1px"  }, visible: false, filtering: false, sortable: false},
                  { key: "compliance_lrza", label: this.trans('lrza',182), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "25px"  }, visible: false, filtering: false, sortable: false},
                  { key: "compliance_reference", label: this.trans('fp-reference',172), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "8px","padding-right": "5px"  }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_payroll_tax", label: this.trans('dashboard-payroll-tax-statement',201), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center","padding-right": "5px"   }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_btw_number", label: this.trans('btw',182), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center", "padding-left": "25px"  }, visible: true, filtering: false, sortable: false},
                  { key: "compliance_proof_registration", label: this.trans('fw-proof-of-registration',196), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center","padding-right": "5px" }, visible: false, filtering: false, sortable: false},
                  { key: "compliance_training_contract", label: this.trans('fp-training-agreement',172), thStyle: { "min-width": "105px", "max-width": "105px", "text-align": "center","padding-right": "5px" }, visible: false, filtering: false, sortable: false},

  
              ],
              items: 
                  {
                      list:[
                      ],
                      total: 0,
                      refresh: 0,
                  },
  
              list_options: [
                  { value: 1, name: this.trans('d-compliant-flex',203) },
                  { value: 2, name: this.trans('d-not-compliant-flex',203) },
                  { value: 3, name: this.trans('d-all-flexworkers',203) },
                  { value: -1, name: '' },
                  { value: 4, name: this.trans('d-compliant-applicants',203) },
                  { value: 5, name: this.trans('d-not-compliant-applicants',203) },
                  { value: 6, name: this.trans('d-all-applicants',203) }
              ],
              userSettings: new UserSettings(),
              user_locations: null,
              user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
              first_office: null
          };
      },
  
      methods: {
          getList() {
            this.loading_list = true;
             axios
                  .post("settings/users/dashboard/compliancy-check/getList", {
                      filters: JSON.stringify(this.filters),
                  })
                  .then((res) => {                    
                      this.filters.page = res.data.page;
                      this.items = res.data.items;
                  })
                  .catch((error) => {
                      console.error(`error during request: ${error}`);
                  })
                  .finally(() => {
                      this.loading_list = false;
                      this.load = 1;
                  });
          },
  
          getProfile: function(selectedRows) {
              if(selectedRows.length != 0 && selectedRows[0].id_status_const_type != 1){
                  this.$router.push({ name: "people-person-profile", params: { id_person_data_main: selectedRows[0].id_person_data_main } });
              }
          },
  
          changeListType(type){
              this.items.list = [];
              if(type == 1){
                  this.fields[1].visible = true;
                  this.fields[2].visible = true;
                  this.fields[6].visible = false;
                  this.fields[7].visible = false;
                  this.fields[8].visible = false;
                  this.fields[9].visible = false;
                  this.fields[11].visible = true;
                  this.fields[12].visible = false;
                  this.fields[13].visible = false;
                  this.fields[14].visible = false;
              }else if(type == 2){
                  this.fields[1].visible = false;
                  this.fields[2].visible = true;
                  this.fields[6].visible = true;
                  this.fields[7].visible = true;
                  this.fields[8].visible = true;
                  this.fields[9].visible = true;
                  this.fields[11].visible = false;
                  this.fields[12].visible = true;
                  this.fields[13].visible = false;
                  this.fields[14].visible = false;
              }else if(type == 3){
                  this.fields[1].visible = true;
                  this.fields[2].visible = false;
                  this.fields[6].visible = false;
                  this.fields[7].visible = false;
                  this.fields[8].visible = false;
                  this.fields[9].visible = false;
                  this.fields[11].visible = true;
                  this.fields[12].visible = false;
                  this.fields[13].visible = true;
                  this.fields[14].visible = true;
              }
          },
  
          setRegisterStatus(list_type){
              if(list_type.value <= 3){
                  this.filters.register_status = 2;
              } else {
                  this.filters.register_status = 1;
              }
          },
  
          saveSettings() {
              this.userSettings.saveSettings(this.filters, 15);
          },
  
          async loadSettings() {
              var getData = JSON.parse(localStorage.getItem('userSettings'));
  
              if(getData == undefined || getData.dashboardCompliancyCheck == undefined){
                  await this.userSettings.saveSettings(this.filters, 15);
                  getData = JSON.parse(await localStorage.getItem('userSettings'));
              }
                  
              var sFilters = getData.dashboardCompliancyCheck;
  
              this.filters.list_type = sFilters.list_type;
              this.filters.type = sFilters.type;
              this.filters.sorting = sFilters.sorting;
              this.filters.office = sFilters.office;
              this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;
              this.filters.register_status = sFilters.register_status;  
  
              this.changeListType(this.filters.type);
  
              if(this.filters.office != null){
                  this.getList();
              } else if(this.filters.office == null && this.user_locations.length > 0){
                this.filters.office = this.user_locations[0];
                this.getList();
              } else{
                this.refreshUserLocations++;
              }
          },

          async getFiltersOffices(event){
            this.first_office = event[0];

            if(this.filters.office == null ){
                this.filters.office = this.first_office;

                await this.$nextTick();
                this.getList();
            }

          },
  
          onCreateThis() { 
              this.loadSettings();

              this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                  if (!this.loading_list) {
                      this.getList();
                      this.saveSettings()
                  } 
              });
  
              this.$watch('filters.changed', function(newVal, oldVal) {
                  if (!this.loading_list) {
                      this.getList();
                  } 
              });
  
              this.$watch('filters.search', function(newVal, oldVal) {
                  if (!this.loading_list) {
                      this.filters.page = 1;
                      this.getList();
                  } 
              });
  
              this.$watch('filters.list_type', function(newVal, oldVal) {
                  if (!this.loading_list && this.load > 0) {
                      this.setRegisterStatus(newVal);
                      this.getList();
                      this.saveSettings()
                  } 
              });
  
              this.$watch('filters.type', function(newVal, oldVal) {
                  if (!this.loading_list) {
                      this.changeListType(newVal);
                      this.getList();
                      this.saveSettings()
                  } 
              });
             
              this.$watch('items.refresh', function(newVal, oldVal) {
                  if (newVal > 0 && !this.loading_list) {
                      this.getList();
                  }   
              });
  
              this.$watch('filters.office', function(newVal, oldVal) {
                  if (!this.loading_list) {
                      this.getList();
                      this.saveSettings()
                  }   
              });
  
              this.$watch('filters.page', function(newVal, oldVal) {
                  if (!this.loading_list) {
                      this.getList();
                  }   
              });
          },
  
      },
    };
  </script>
  <style scoped>
  
  .app-local-not-applicable{
      color: #F2F4F7;
  }
  
  .app-local-warning{
      color: #F7900980;
  }
  
  .app-local-success{
      color: #12B76A80;
  }
  
  .app-local-danger{
      color: #F0443880;
  }
  
  .app-local-center{
      text-align: center
  }
  </style>
  